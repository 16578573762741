import * as AddressSuggestions from "./address-suggestions";
import * as Alarms from "./alarms";
import * as Auth from "./auth";
import * as BookingDetails from "./booking-details";
import * as Configuration from "./configuration";
import * as Countries from "./countries";
import * as Customers from "./customers";
import * as DeviceVersions from "./device-versions";
import * as Devices from "./devices";
import * as Monitors from "./monitors";
import * as Nodes from "./nodes";
import * as NoiseThresholds from "./noise-thresholds";
import * as Resources from "./resources";
import * as Rooms from "./rooms";
import * as Users from "./users";

export { CustomError } from "./errors";

export default {
  Auth,
  Devices,
  Rooms,
  Users,
  Customers,
  BookingDetails,
  Monitors,
  Countries,
  NoiseThresholds,
  DeviceVersions,
  Alarms,
  Resources,
  Nodes,
  Configuration,
  AddressSuggestions,
};
