import { useContext } from "react";
import "./internationalization-config.lib";

import { InternationalizationProvider } from "./internationalization-component.context.lib";
import { InternationalizationContext } from "./internationalization.context.lib";
import { supportedLanguages, SupportedLanguages } from "./internationalization-model.context.lib";

const useInternationalization = (basePath = "") => {
  const { t, changeLanguage, currentLanguage } = useContext(InternationalizationContext);
  return {
    t: (key: string, options?: Record<string, unknown>) =>
      t(basePath ? `${basePath}.${key}` : key, options),
    getRootTranslation: (key: string, options?: Record<string, unknown>) => t(key, options),
    changeLanguage,
    currentLanguage,
  };
};

export default InternationalizationProvider;

export { useInternationalization, supportedLanguages, SupportedLanguages };

export { TranslateComponent } from "./internationalization-component.lib";
