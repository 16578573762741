import { useQueryClient } from "@tanstack/react-query";

import { authMeKey, autoLoginKey } from "./auth-query-keys";
import { useAuthentication } from "../../../../lib/contexts";
import ApiError from "../../errors/ApiError";
import { useMutationWrapper } from "../wrappers";
import useApiError from "@/lib/hooks/useApiError.hook";
import API from "@/services/API";

interface AuthAutoLoginMutationParams {
  handleSuccess: () => void;
  handleError: () => void;
}

export function useMutationAuthAutoLogin({
  handleSuccess,
  handleError,
}: AuthAutoLoginMutationParams) {
  const { handleErrors } = useApiError();
  const { refreshUser, getUser } = useAuthentication();

  const queryClient = useQueryClient();

  const { mutate, isError, isPending } = useMutationWrapper({
    mutationKey: autoLoginKey(),
    mutationFn: API.Auth.autoLogin,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: authMeKey() });
      refreshUser();
      handleSuccess();
    },
    onError: async (err: ApiError) => {
      handleError();
      const user = await getUser();
      if (user) return;
      handleErrors(err);
    },
    captureUnauthorizedError: false,
  });

  return {
    mutate,
    isError,
    isPending,
  };
}
