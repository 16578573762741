import { TOptionsBase } from "i18next";
import { $Dictionary } from "i18next/typescript/helpers";

export type SupportedLanguages = "en" | "es";

export type Internationalization = {
  t: (key: string | string[], options?: TOptionsBase & $Dictionary) => string;
  changeLanguage: (language: SupportedLanguages) => void;
  currentLanguage: SupportedLanguages;
};

export const defaultInternationalization: Internationalization = {
  t: (key: string) => key,
  changeLanguage: () => undefined,
  currentLanguage: "en",
};

export const supportedLanguages: SupportedLanguages[] = ["en", "es"];
