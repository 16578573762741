import * as React from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { SxProps, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface SelectComponentProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  menuItems: React.ReactNode[];
  readonly?: boolean;
  disabled?: boolean;
  additionalMenuItem?: React.ReactNode;
  sx?: SxProps;
  isLoading?: boolean;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled";
}
export const SelectCustomComponent = ({
  label,
  value,
  onChange,
  menuItems,
  additionalMenuItem,
  readonly = false,
  disabled = false,
  isLoading = false,
  sx,
  required,
  variant = "standard",
}: SelectComponentProps): JSX.Element => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedZone = event.target.value;
    onChange(selectedZone);
  };
  return (
    <FormControl
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        "&.MuiFormControl-root .MuiInputBase-root": {
          marginTop: 0,
        },
        "&.MuiFormControl-root .MuiInputBase-root .MuiSelect-select": {
          padding: "0 !important",
        },
      }}
      disabled={disabled}
    >
      <FormLabel
        color="info"
        id={`label-${label}`}
        htmlFor={`id-${label}`}
        sx={{
          fontSize: theme.typography.caption.fontSize,
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
        required={required}
      >
        {label}
      </FormLabel>

      <Select
        id={`id-${label}`}
        labelId={`label-${label}`}
        readOnly={readonly}
        value={value}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        variant={variant}
        IconComponent={(props) => <KeyboardArrowDown {...props} fontSize={"medium"} />}
        endAdornment={
          isLoading ? (
            <InputAdornment position="end">
              <CircularProgress
                size={14}
                color="info"
                sx={{
                  marginRight: 3,
                }}
              />
            </InputAdornment>
          ) : null
        }
        sx={{
          paddingY: 0.5,
          minHeight: 40,
        }}
      >
        {menuItems}
        {additionalMenuItem}
      </Select>
    </FormControl>
  );
};
