import { InputLabel, TextField, styled } from "@mui/material";

export const StyledOutlinedInputLabel = styled(InputLabel)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "120%",
  color: theme.palette.gray[10],
  marginBottom: "4px",
}));

export const StyledOutlinedInput = styled(TextField)<{
  error?: boolean;
  success?: boolean;
  allowError?: boolean;
}>(({ theme, error, success, allowError }) => {
  const errorStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44335' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44335' stroke='none'/%3E%3C/svg%3E\")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `right ${theme.functions.pxToRem(16)} top ${theme.functions.pxToRem(12)}`,
    backgroundSize: `${theme.functions.pxToRem(16)} ${theme.functions.pxToRem(16)}`,

    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: theme.palette.error.main,
      },
    },
  });

  const successStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%234CAF50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `right ${theme.functions.pxToRem(16)} top ${theme.functions.pxToRem(12)}`,
    backgroundSize: `${theme.functions.pxToRem(16)} ${theme.functions.pxToRem(16)}`,

    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: theme.palette.success.main,
      },
    },
  });

  return {
    minHeight: allowError ? 60 : "auto",
    ...(error && errorStyles()),
    ...(success && successStyles()),
    "& .MuiOutlinedInput-root": {
      height: "41px",
      padding: "12px",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      lineHeight: "120%",
      padding: 0,
    },
  };
});
