export const monthNameFormat = (currentMonth: number, locale: string = "es") => {
  if (currentMonth < 0 || currentMonth > 11) {
    throw new Error("Invalid month number");
  }

  const monthName = new Intl.DateTimeFormat(locale, { month: "long" }).format(
    new Date(2000, currentMonth, 1)
  );

  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
};
