import { AUTO_LOGIN_URL } from "./api-url.constants";
import WrongCredentialsError from "./errors/WrongCredentialsError";
import { API_METHODS } from "../../shared";
import { typedFetch } from "@/lib/helpers";

export const autoLogin = async (hash: string): Promise<boolean> => {
  const result = await typedFetch(AUTO_LOGIN_URL, {
    method: API_METHODS.POST,
    body: JSON.stringify({ hash }),
  });

  if (!result.ok) {
    throw new WrongCredentialsError();
  }

  return true;
};
