import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { KeyValue } from "../../../models";
import { SxProps, Theme } from "@mui/material";

interface AutocompleteComponentProps {
  options: KeyValue[];
  value?: KeyValue;
  onChange: (value: KeyValue) => void;
  label?: string;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  dataTestId?: string;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
  popupIcon?: React.JSX.Element;
}

export function AutocompleteComponent({
  options,
  onChange,
  label,
  name,
  disabled,
  readOnly,
  fullWidth,
  value,
  placeholder,
  dataTestId,
  required,
  variant = "standard",
  sx,
  popupIcon,
}: AutocompleteComponentProps): JSX.Element {
  return (
    <Autocomplete
      id={"autocomplete-" + name}
      data-testid={dataTestId}
      popupIcon={popupIcon}
      filterOptions={(x, e) => {
        const value = e.inputValue;
        const filteredOptions = x.filter((option) =>
          option.value.toLowerCase().includes(value.toLowerCase())
        );

        const uniqueOptions = Array.from(
          new Map(filteredOptions.map((option) => [option.value.toLowerCase(), option])).values()
        );

        return uniqueOptions;
      }}
      autoComplete
      includeInputInList
      disabled={disabled}
      readOnly={readOnly}
      fullWidth={fullWidth}
      onChange={(_event: unknown, value: KeyValue) => {
        onChange(value);
      }}
      value={value}
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (!value) return false;
        return option.key === value.key;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.value}
        </li>
      )}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.value)}
      style={{ width: "100%" }}
      sx={sx}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          variant={variant}
          inputProps={{ ...params.inputProps }}
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          required={required}
        />
      )}
    />
  );
}
