import React, { useMemo } from "react";

import { useInternationalization } from "../../contexts";
import { SupportedLanguages, supportedLanguages } from "../../contexts/internationalization";
import { CardComponent } from "../card";
import MDBox from "../md-box";
import MDTypography from "../md-typography";
import { SelectComponent } from "../select-component";

export const LanguageSelectorComponent: React.FC = () => {
  const { currentLanguage, t, changeLanguage } = useInternationalization("language-selector");

  const handleLanguageChange = (code: SupportedLanguages) => {
    changeLanguage(code);
  };

  const items = useMemo(
    () =>
      supportedLanguages.map((lang) => ({
        key: lang,
        value: t(`locales.${lang}`),
      })),
    [t]
  );

  return (
    <CardComponent id="language" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5" fontWeight="medium">
          {t("title")}
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <SelectComponent
          menuItems={items}
          onChange={handleLanguageChange}
          label={t("label")}
          value={currentLanguage}
        />
      </MDBox>
    </CardComponent>
  );
};
